import React from 'react'
import styled from 'styled-components'
import breakpoints from '../../breakpoints'
import { HeadingWithLeftbar, ArticleTextStyle } from '../../tokens/fontStyles'

const Component = props => {
  const image = props.image ? (
    <img src={props.image} alt={props.image.url} />
  ) : (
    ''
  )
  return (
    <Container>
      <h4>{props.title}</h4>
      <p>{props.text}</p>
      {image}
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 60px;
  ${breakpoints.lessThan('pc')`
    margin-bottom: 40px;
  `}
  &:last-of-type {
    margin-bottom: 80px;
    ${breakpoints.lessThan('pc')`
      margin-bottom: 60px;
    `}
  }
  h4 {
    margin-bottom: 20px;
    ${HeadingWithLeftbar}
  }
  p {
    ${ArticleTextStyle};
    margin-bottom: 24px;
  }
  figure {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
`

export default Component
