import React from 'react'
import styled from 'styled-components'
import Article from '../modules/containers/VerticalContent'
import breakpoints from '../breakpoints'
import { flex } from '../tokens/positionings'

const Component = ({ data }) => {
  const contents = []
  for (let content of data) {
    if (content.image) {
      contents.push(
        <Article
          title={content.title}
          text={content.text}
          image={content.image.url}
        />
      )
    } else {
      contents.push(<Article title={content.title} text={content.text} />)
    }
  }
  return (
    <Section>
      <Article />
      {contents}
      <Article />
    </Section>
  )
}

const Section = styled.ul`
  max-width: 980px;
  margin: 0 auto;
  ${flex('space-between')};
  ${breakpoints.lessThan('pc')`
  padding: 20px 0;
`}
`
export default Component
