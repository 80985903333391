import React from 'react'
import styled from 'styled-components'
import breakpoints from '../../breakpoints'

const Component = ({ data }) => {
  return (
    <Profile>
      <figure>
        <img src={data.image.url} alt={data.name} />
      </figure>
      <div>
        <h4>
          {data.name}
          <span>{data.position}</span>
        </h4>
        <p class="card_profile">{data.profile}</p>
      </div>
    </Profile>
  )
}
const Profile = styled.div`
  display: flex;
  max-width: 980px;
  margin: 0 auto;
  ${breakpoints.lessThan('pc')`
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    margin-bottom: 40px;
    padding: 0 20px;
`}
  ${breakpoints.greaterThan('pc')`
  border: 1px solid #C4C4C4;
  margin-bottom: 60px;
`}
figure {
    width: 50%;
    ${breakpoints.lessThan('pc')`
    width: calc(100% + 40px);
    margin-left: -20px;
  `}
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  div {
    letter-spacing: 0.04em;
    line-height: 1.5;
    ${breakpoints.greaterThan('pc')`
    width: 50%;
    padding: 20px 78px;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
  `}
    ${breakpoints.lessThan('pc')`
    padding: 20px 0;
`}
  h4 {
      font-size: 1.8rem;
      font-weight: 400;
      span {
        display: block;
        font-size: 1.4rem;
        color: $cl_txt;
        margin: 10px 0 18px;
        color: #7e7e7e;
      }
    }
    p {
      font-size: 1.4rem;
      color: $cl_txt;
    }
  }
`

export default Component
